export default {
  props: [
    'field',
    'data',
    'dictionaries',
    'backend_errors',
    'inputRequiredEmpty',
    'mode',
  ],
  computed: {
    value: {
      get() {
        if (this.data && this.field.dbName) {
          return this.field.dbName.startsWith('custom/')
                 ? this.data.custom[this.field.dbName.split('/')[1]]
                 : this.data[this.field.dbName];
        }
        return '';
      },
      set(newVal) {
        if (this.field.dbName.startsWith('custom/')) {
          this.data.custom[this.field.dbName.split('/')[1]] = newVal;
        } else {
          this.data[this.field.dbName] = newVal;
        }
      },
    },
    meta() {
      return this.field.meta;
    },
  },
};
