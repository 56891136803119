<template>
  <div class="generator--form-item">
    <el-form-item
        :class="[field.label && field.label.labelPosition ? 'el-form--labelPosition-' + field.label.labelPosition : 'el-form--labelPosition-top']"
        :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"
        :required="field.meta && field.meta.required ? field.meta.required : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
    >
      <template slot="label">
        <Tooltip
            :content="field.label && field.label.name ? $t(field.label.name).toUpperCase() : ''"
            :labelTooltip="field.label.labelTooltip"
            v-if="field.label && field.label.labelTooltip"
        />
        <span
            class="labelWrap"
            v-else
        >{{ field.label && field.label.name ? $t(field.label.name).toUpperCase() : '' }}</span>
        <slot name="label" />
      </template>
      <div class="px-2">
        <slot />
      </div>
    </el-form-item>
    <hr
        class="separator"
        v-if="field.separator"
    />
  </div>
</template>

<script>

export default {
  name: 'FormItem',
  props: ['field'],
  components: {'Tooltip': () => import('./Tooltip.vue')},
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.generator--form-item {
  .el-form-item {
    margin: 5px 0;

    .el-form-item__content {
      .lineHeight--initial {
        line-height: initial;
      }

      /*line-height: initial;*/
      display: inline-block;
      margin: 0 !important;
      width: 100%;
    }
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.separator {
  border: 0.5px solid rgb(221, 225, 228);
  margin: 0 -15px;
}
</style>
