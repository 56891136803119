<template>
  <base-form-item :field="field">
    <el-switch
        name="switchInheritMembers"
        v-model="value"
    ></el-switch>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  name: 'CreateOrUpdate_checkbox',
  mixins: [abstractForm],
  components: {BaseFormItem},
  beforeMount() {
    if (!this.value) {
      this.value = false;
    }
  },
};
</script>

<style scoped>

</style>
